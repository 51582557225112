import './index.pcss'
import { ethers } from 'ethers'
import detectEthereumProvider from '@metamask/detect-provider';
import axios from 'axios'


(async () => {
    // Connect
    const connect = document.querySelector('#metamask-connect')

    // Connected
    const connected = document.querySelector('#metamask-connected')
    const credits = document.querySelector('#credits')
    const params = new URLSearchParams(window.location.search)
    const provider = await detectEthereumProvider();

    if (provider) {
        console.log("Metamask installed")
    } else {
        console.log('Please install MetaMask!');
    }

    if (!params.has('code')) {
        alert("The link you have used is invalid or expired")
        // TODO: Make the page look disabled
    }
    const code = params.get('code')
    // TODO: Verify Code Here (on page load)


    connect.onclick = async () => {
        //Check if the code is valid/expired and code is already used and user is verified
         let { data } = await axios.post('/.netlify/functions/checkCode', { code })
        
        if (!data.verified) {
            alert("Link is invalid. Please try verifying again on discord.")
            return;
        }
        
        // Get the user account
        const accounts = await ethereum.enable();
        const account = accounts[0];
        const provider = new ethers.providers.Web3Provider(window.ethereum)

            // The Metamask plugin also allows signing transactions to
            // send ether and pay to change state within the blockchain.
            // For this, you need the account signer...
        const signer = provider.getSigner()
        const message = await signer.signMessage(code)
        data = await axios.post('/.netlify/functions/verify', { message, code, account })
        console.log(data)
        console.log(account)
        console.log(provider)

            if (data.data.verified) {
                connect.classList.add('hidden')
                connected.classList.remove('hidden')
                credits.classList.remove('hidden')
                setTimeout(() => {
                    alert("Verification successful! You can safely close this page.")
                }, 30)
            } else {
                alert("Verification was unsuccessful. Please try verifying again on discord.")
            }
    }

})()
